import { useEffect, useRef, useState } from 'react';
import './App.css';
import Papa from 'papaparse';
import StreamComponent from './streame';
import axios from 'axios';
import { Grid2 } from '@mui/material';


function App() {

  const [csvData, setCsvData] = useState([]);
  const [videoData, setVideoData] = useState();
  const [styleColor, setStyleColor] = useState();
  const [gridView, setGridView] = useState(false)
  const currentDate = new Date();
  const formattedDate = currentDate.toDateString();
  const [backgroundSideMenu, setBackgroundSideMenu] = useState()
  const iframeref = useRef()
  const [relodIndex, setRelodIndex] = useState({ id: -1, random: 0 })
  const [data,setdata] = useState([])
  const [eventData,setEventdata] = useState([]) 
 
  useEffect(() => {
    console.log(relodIndex)
  }, [relodIndex])
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.post("https://crapi.kfintech.com/api/emeetings/upcomingEvents", {
          key: "poYXQNUIT6o6i1pp2p2GNLYP7PJ1j8gh", 
        });

        if (response.data.status_code === "00" && response.data.events.length > 0) {
          const event = response.data.events[0];
          const { room_name, octa_server_url } = event;
          let data = response.data.events.map(event => {
            return {
              ...event, // Spread the existing event object properties
              octa_server_id: Number(event.octa_server_id) - 9 // Convert octa_server_id to number and subtract 9
            };
          })
          // let data = response.data.events.forEach(event => {
          //   // Convert octa_server_id to a number, subtract 9, and update the value
          //   event.octa_server_id = Number(event.octa_server_id) - 9;
          // })
          console.log(data)
          setEventdata(data)
          // setVideoJsOptions({
          //   autoplay: true,
          //   controls: true,
          //   preload: "auto",
          //   autofocus: true,
          //   sources: [
          //     {
          //       src: `${octa_server_url}/live/${room_name}/index.m3u8`,
          //       type: "application/x-mpegURL",
          //     },
          //   ],
          //   fluid: true,
          // });
          // setStream(true);
          // setLoading(false);
        } else {
          console.error("Failed to fetch events");
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching event details", error);
        // setLoading(false);
      }
    };

    fetchEventDetails();
  }, []);
  function formatDate(date) {
    
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with '0' if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (zero-based) and pad with '0' if needed
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`;
  }

  const checkdate=(date)=>{
    console.log(date)
    const givenDate = formatDate(new Date(date));
    const currentDate = formatDate(new Date());
    
    console.log("fghj", givenDate)
    console.log("sd", currentDate)
    if (givenDate === currentDate) {
      return true
    }
    else {
      return false
    }
  }
  useEffect(()=>{
    console.log(eventData)
  },[eventData])
   
  // useEffect(() => {
  //   // Fetch the CSV file using a fetch request
  //   fetch("./data.csv")
  //     .then(response => response.text())
  //     .then(text => {
  //       const result = Papa.parse(text, { header: true });
  //       setCsvData(result.data);
  //     });
  // }, []);

  // useEffect(() => {
  //   console.log(csvData)
  // }, [csvData])
  // useEffect(() => {
  //   console.log(iframeref)
  // }, [videoData])

  const handleVideo = (item) => {
    setVideoData(item)
    setBackgroundSideMenu(item)
    setStyleColor(item.company_name)
  }
  const handleIframeLoad = () => {
    document.getElementById("hai")
    // iframeref.current.click()
    // // Access the iframe's content window
    // const iframeContentWindow = iframeref.current.contentWindow;
    // iframeContentWindow.click()
    // Execute JavaScript within the iframe's content window
    // iframeContentWindow.eval(`
    //   // Access the video element within the iframe
    //   const video = document.querySelector('video');

    //   // Play the video
    //   video.play();
    // `);
  };

  return (
    <>
    <div></div>
      <div className='header'>
        <h3 className='h3'>Welcome to the Shareholder View Dated:- {formattedDate}</h3>
        <div style={{ position: "absolute", zIndex: "2", right: "10px", top: "5px" }}>
          <button style={{
            width: "80px",
            height: "30px",
            borderRadius: "15px",
            backgroundColor: '#824cae',
            color: "white", border: "none", cursor: "pointer"
          }}

            onClick={() => setGridView(!gridView)}>
            {!gridView ? "Grid view" : "Side View"}
          </button>
        </div>
      </div>

      <div className='main-body'>
        {!gridView &&
          <>
            <div className='sideMenu'>
              {eventData.filter((item)=>checkdate(item.vc_start_time)).map((item, index) => {
                return (
                  <h4 className="h4" key={index} onClick={() => handleVideo(item)} style={{ color: styleColor === item.company_name ? "#824cae" : "" }}>
                    {item.company_name}
                  </h4>
                )
              })}
            </div>
            <div className='streamer-video'>
              {videoData && <div >
                <span className="streamer_heading">Meeting ID : KFIN{videoData.event_id} |  {videoData.company_name} | {videoData.vc_start_time?videoData.vc_start_time:""} </span>
                <div  >
                  <iframe id="hai" ref={iframeref} onLoad={handleIframeLoad} src={`https://kfinlive${videoData.octa_server_id}.octacomm.io/join/KFIN${videoData.event_id}/connect`} allow="autoplay"  width="100%" title="Iframe Example" style={{height:"90vh"}}></iframe>
                </div>
              </div>}
            </div>
          </>
        }
        {gridView &&
          <>
            {/* <div className='gridview'> */}
            <Grid2 container spacing={{ xs: 2, md: 3 }}padding={"30px"} >
              {eventData.filter((item)=>checkdate(item.vc_start_time)).map((item, index)=> {
                return (
                    <Grid2 size={{ xs: 2, sm: 4, md: 4 ,lg:4,xl:3}} sx={{width:"100%"}}>
                    {/* className="griditemView" style={{ marginRight: "20px", marginLeft: "20px" }} */}
                      <div  style={{width:"100%"}}>
                        <button style={{background:"none",border:"none",cursor:"pointer",marginRight:"10px",padding:"0px"}}onClick={() => setRelodIndex(() => { return ({ id: index, random: Math.random() }) })}>
                          <svg
                          style={{margin:"0px",padding:"0px"}}
                          width="100%"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.1459 11.0499L12.9716 9.05752L15.3462 8.84977C14.4471 7.98322 13.2242 7.4503 11.8769 7.4503C9.11547 7.4503 6.87689 9.68888 6.87689 12.4503C6.87689 15.2117 9.11547 17.4503 11.8769 17.4503C13.6977 17.4503 15.2911 16.4771 16.1654 15.0224L18.1682 15.5231C17.0301 17.8487 14.6405 19.4503 11.8769 19.4503C8.0109 19.4503 4.87689 16.3163 4.87689 12.4503C4.87689 8.58431 8.0109 5.4503 11.8769 5.4503C13.8233 5.4503 15.5842 6.24474 16.853 7.52706L16.6078 4.72412L18.6002 4.5498L19.1231 10.527L13.1459 11.0499Z"
                            fill="currentColor"
                          />
                        </svg> </button>
                        <span className="streamer_heading" >
                          Meeting ID : KFIN{item.event_id} | {item.company_name} | {item.vc_start_time?item.vc_start_time:""}
                        </span>
                      </div>
                      {relodIndex.id === index ? (
                        // Reload this iframe by changing its src
                        <iframe src={`https://kfinlive${item.octa_server_id}.octacomm.io/join/KFIN${item.event_id}/connect`}
                          allow="autoplay"
                          height="440px"
                          width="100%"
                          title="Iframe Example" key={relodIndex.random} />
                      ) : (
                        // Display the iframe without reloading
                        <iframe
                          src={`https://kfinlive${item.octa_server_id}.octacomm.io/join/KFIN${item.event_id}/connect`}
                          allow="autoplay"
                          height="440px"
                          width="100%"
                          title="Iframe Example" >
                        </iframe>
                      )}
                      {/* <iframe
                        src={`https://kfinlive${item.serverno}.octacomm.io/join/KFIN${item.code}/connect`}
                        allow="autoplay"
                        height="440px"
                        width="600px"
                        title="Iframe Example" >
                      </iframe> */}
                    </Grid2>
                )
              })}
              </Grid2>
            {/* </div> */}
          </>
        }
      </div>
      {StreamComponent}
    </>
  );
}

export default App;

