import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./Streamer.css";
import axios from "axios";

function StreamComponent(props) {
  const [stream, setStream] = useState(false);
  const [videoJsOptions, setVideoJsOptions] = useState(null);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.post("https://crapi.kfintech.com/api/emeetings/upcomingEvents", {
          key: "poYXQNUIT6o6i1pp2p2GNLYP7PJ1j8gh", 
        });

        if (response.data.status_code === "00" && response.data.events.length > 0) {
          const event = response.data.events[0];
          const { room_name, octa_server_url } = event;
          console.log(response.data)
          
          setVideoJsOptions({
            autoplay: true,
            controls: true,
            preload: "auto",
            autofocus: true,
            sources: [
              {
                src: `${octa_server_url}/live/${room_name}/index.m3u8`,
                type: "application/x-mpegURL",
              },
            ],
            fluid: true,
          });
          setStream(true);
          setLoading(false);
        } else {
          console.error("Failed to fetch events");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching event details", error);
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, []);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("video-js", "vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, videoJsOptions, () => {
        videojs.log("player is ready");
      });

      videoRef.current.click();
    } else {
      const player = playerRef.current;
      if (player && videoJsOptions) {
        player.autoplay(videoJsOptions.autoplay);
        player.src(videoJsOptions.sources);
      }
    }
  }, [videoJsOptions, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <>
      {loading ? (
        "Loading..."
      ) : stream ? (
        <div className="streamer" data-vjs-player ref={videoRef}></div>
      ) : (
        "Loading ..."
      )}
    </>
  );
}

export default StreamComponent;
